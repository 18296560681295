import WebStorage from 'vue-web-storage';
export default function StoragePlugin(Vue, options) {
    var _a;
    Vue.use(WebStorage, {
        prefix: 'grizoon_',
        drivers: [(_a = options.driver) !== null && _a !== void 0 ? _a : 'local'],
    });
    function generateKey(key) {
        let arr = [key];
        if (options.prefix) {
            arr.unshift(options.prefix);
        }
        return arr.join('_');
    }
    Vue.prototype.$getStorage = function (key, fallback) {
        return this.$localStorage.get(generateKey(key), fallback);
    };
    Vue.prototype.$setStorage = function (key, value) {
        return this.$localStorage.set(options.prefix + '_' + key, value);
    };
}
