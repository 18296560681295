import Vue from 'vue';
import 'bootstrap';
import * as $ from 'jquery';
export default Vue.extend({
    mounted() {
        $(this.$el)
            .find('.dropdown-toggle')
            .dropdown();
    },
});
