var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    [
      _c("li", [
        _vm.parent
          ? _c(
              "a",
              {
                attrs: { href: _vm.parent.url },
                on: {
                  click: function($event) {
                    return _vm.FilterChanged(_vm.parent.slug)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.label))]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm._l(_vm.menuItems, function(item) {
        return _c(
          "li",
          {
            key: item.id,
            class: { Selected: item.id === parseInt(_vm.active) }
          },
          [
            item.children.length === 0
              ? _c(
                  "a",
                  {
                    attrs: { href: item.url },
                    on: {
                      click: function($event) {
                        return _vm.FilterChanged(item.slug)
                      }
                    }
                  },
                  [_vm._v(_vm._s(item.name))]
                )
              : _c("span", [_vm._v(_vm._s(item.name))]),
            _vm._v(" "),
            item.children.length !== 0
              ? _c("MenuItem", {
                  attrs: {
                    "menu-items": item.children,
                    active: _vm.active,
                    parent: item,
                    label: _vm.label,
                    type: _vm.type
                  }
                })
              : _vm._e()
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }