import '@global/eventHub';
import * as $ from 'jquery';
import Vue from 'vue';
import Cart from '@mixins/Cart';
import CategoryMenu from '@mixins/CategoryMenu';
import Dropdowns from '@mixins/Dropdowns';
import LockBody from '@mixins/LockBody';
import { BModal, BCollapse, VBToggle } from 'bootstrap-vue';
import breakpoints from '@eli5/bootstrap-breakpoints-vue';
import ApiRequest from '@services/ApiRequestService';
Vue.use(breakpoints);
new Vue({
    el: '#top-header-wrapper',
    components: {
        'b-modal': BModal,
        'b-collapse': BCollapse,
    },
    directives: {
        'b-toggle': VBToggle,
    },
    mixins: [CategoryMenu, Cart, Dropdowns, LockBody],
    data() {
        return {
            minKeyword: 3,
            productsPreview: [],
            sellersPreview: [],
            categoriesPreview: [],
            pager: {},
            timeout: null,
            resultsLoaded: false,
            resultsLoading: false,
        };
    },
    mounted() {
        $('#search__toggle-button').on('click', (e) => {
            e.preventDefault();
            $('body').toggleClass('search-opened').removeClass('menu-opened');
            window.eventHub.$emit('CartClose');
            if ($('body').hasClass('search-opened')) {
                this.LockBackgroundScroll();
            }
            else {
                this.UnlockBackgroundScroll();
            }
        });
        window.eventHub.$on('OpenSearchMenu', () => {
            window.eventHub.$emit('CloseMainMenu');
            $('body').addClass('search-opened');
            this.LockBackgroundScroll();
        });
        window.eventHub.$on('CartOpen', () => {
            $('body').removeClass('menu-opened search-opened');
        });
    },
    methods: {
        FetchProductPreviewList(keyword) {
            let path = ''; //location.pathname;
            let query = keyword ? '?keyword=' + keyword : '';
            this.resultsLoading = true;
            ApiRequest.post('/shop/fetch' + query, {
                path,
            }).then((response) => {
                let results = response.data.message;
                this.productsPreview = results.data.products;
                this.sellersPreview = results.data.sellers;
                this.categoriesPreview = results.data.categories;
                this.pager = results.data.pager;
                this.resultsLoaded = true;
                this.resultsLoading = false;
                window.eventHub.$emit('OpenSearchMenu');
            });
        },
        SearchKeyword() {
            clearTimeout(this.timeout);
            let keyword = this.keyword;
            if (keyword.length < this.minKeyword) {
                this.resultsLoaded = false;
                if (this.$mediaBreakpointUp('lg')) {
                    $('body').removeClass('search-opened');
                }
                return;
            }
            this.timeout = setTimeout(() => {
                this.FetchProductPreviewList(keyword);
            }, 750);
        },
        FocusKeyword() {
            if (this.keyword.length > 0) {
                window.eventHub.$emit('OpenSearchMenu');
            }
        },
        BlurKeyword() {
            if (this.$mediaBreakpointUp('lg')) {
                setTimeout(() => {
                    $('body').removeClass('search-opened');
                }, 200);
            }
        },
    },
});
