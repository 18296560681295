import Vue from 'vue';
import '@global/eventHub';
import ApiRequest from '@services/ApiRequestService';
import LazyLoad from 'vanilla-lazyload';
export default Vue.extend({
    data() {
        return {
            items: [],
            total: 0,
            quantity: '',
            cartValue: '',
        };
    },
    mounted() {
        window.eventHub.$on('CartQuantityUpdate', (id, qty = 1) => {
            this.cartCount = qty;
        });
        window.eventHub.$on('CartOpen', () => {
            this.ShowCart();
        });
        window.eventHub.$on('CartClose', () => {
            this.$refs.CartRef.hide();
        });
        window.eventHub.$on('CartOpenSwa', (response) => {
            this.items = response.list || [];
            this.total = response.total;
            this.quantity = response.quantity;
            let ref = this.$refs.CartRef;
            if (ref.is_visible) {
                ref.hide();
                return;
            }
            ref.show();
            return;
        });
    },
    methods: {
        openSwaCart() {
            if (window.innerWidth > 576)
                window.QsshopSwa.getCart();
            else
                window.QsshopSwa.newTabCart();
        },
        openCart() {
            window.eventHub.$emit('CartOpen');
        },
        GoToCart() {
            window.QsshopSwa.goToCart();
        },
        ShowCart() {
            let ref = this.$refs.CartRef;
            if (ref.is_visible) {
                ref.hide();
                return;
            }
            ref.show();
            this.getCart();
            return;
        },
        RemoveFromCart(id, qty = 1) {
            ApiRequest.delete('/cart/' + id).then((response) => {
                let data = response.data.message;
                window.notifications.$flash(data.message, 'success');
                window.eventHub.$emit('CartQuantityUpdate', id, data.quantity);
                window.eventHub.$emit('CartPreviewUpdate');
                this.getCart();
                if (undefined !== data.redirect) {
                    window.location = data.redirect;
                    return;
                }
            }, (error) => { });
            return;
        },
        RemoveFromCartSwa(id, qty = 1) {
            window.QsshopSwa.removeFromCart(id, qty);
        },
        getCart() {
            ApiRequest.get('/cart').then((response) => {
                let data = response.data.message;
                this.$data.items = data.list || [];
                this.$data.total = data.total;
                this.$data.quantity = data.quantity;
                window.eventHub.$emit('CartQuantityUpdate', data.raw.quantity, data.raw.quantity);
                setTimeout(() => {
                    new LazyLoad({
                        elements_selector: '.lazy',
                    });
                });
            }, (error) => { });
        },
    },
});
