import '@global/eventHub';
import * as $ from 'jquery';
import Vue from 'vue';
import LockBody from '@mixins/LockBody';
import ApiRequest from '@services/ApiRequestService';
import Storage from '@plugins/StoragePlugin';
import breakpoints from '@eli5/bootstrap-breakpoints-vue';
import CategoryMenuComponent from '@components/CategoryMenuComponent.vue';
Vue.use(breakpoints);
Vue.use(Storage, {
    prefix: 'global',
});
export default Vue.extend({
    data() {
        return {
            activeCategory: '',
            categoriesMenu: [],
            menu: null,
            title: null,
            motivational: null,
        };
    },
    mixins: [LockBody],
    components: { CategoryMenuComponent },
    created() {
        window.addEventListener('resize', this.FetchCategories);
    },
    mounted() {
        window.eventHub.$on('ActiveCategory', (category) => {
            this.activeCategory = category;
        });
        // Build main menu as a categories list
        this.FetchCategories();
        this.motivational = this.$refs['motivational_messages'].outerHTML;
        // jquery toggle class as vue needs to access body element
        $('#main-menu__hamburger__mobile').on('click', (e) => {
            e.preventDefault();
            window.eventHub.$emit('OpenMainMenu');
            window.eventHub.$emit('CartClose');
        });
        // $('#menu-backdrop__icon-close').on('click', (e: any) => {
        //   e.preventDefault();
        //   window.eventHub.$emit('CloseMainMenu');
        // });
        window.eventHub.$on('OpenMainMenu', () => {
            let motivationalType = this.$getStorage('usp_type');
            $('#category-menu').toggleClass(motivationalType);
            if ($('body').hasClass('menu-opened')) {
                window.eventHub.$emit('CloseMainMenu');
                return;
            }
            $('body').addClass('menu-opened').removeClass('search-opened');
            this.LockBackgroundScroll();
            this.$forceUpdate();
        });
        window.eventHub.$on('CloseMainMenu', () => {
            $('body').removeClass('menu-opened search-opened');
            this.UnlockBackgroundScroll();
        });
        let motivationalType = this.$getStorage('usp_type', 'buy_online');
        $('#content').addClass(motivationalType);
    },
    methods: {
        FetchCategories() {
            if (this.$mediaBreakpointDown('lg')) {
                const params = window.location.search;
                const menuStorageName = 'menu.' + window.locale;
                const menuTitleStorageName = 'menuTitle.' + window.locale;
                let categories = this.$getStorage(menuStorageName);
                window.removeEventListener('resize', this.FetchCategories);
                if (categories && categories.length) {
                    this.categoriesMenu = categories;
                    this.title = this.$getStorage(menuTitleStorageName, 'Menu');
                }
                ApiRequest.get('/category/list' + params).then((response) => {
                    let data = response.data.message;
                    let categoriesMenu = data['categories'];
                    let title = data['title'];
                    if (!categories) {
                        this.categoriesMenu = categoriesMenu;
                        this.title = title;
                    }
                    this.$setStorage(menuStorageName, categoriesMenu);
                    this.$setStorage(menuTitleStorageName, title);
                }, (error) => { });
            }
        },
    },
});
