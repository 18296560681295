import Vue from 'vue';
import breakpoints from '@eli5/bootstrap-breakpoints-vue';
Vue.use(breakpoints);
export default Vue.extend({
    data() {
        return {
            bodyScrollPos: 0,
        };
    },
    mounted() {
        this.$root.$on('bv::modal::show', (bvEvent) => {
            this.LockBackgroundScroll();
        });
        this.$root.$on('bv::modal::hide', (bvEvent) => {
            this.UnlockBackgroundScroll();
        });
    },
    methods: {
        LockBackgroundScroll() {
            if (this.$mediaBreakpointUp('sm')) {
                return;
            }
            this.bodyScrollPos = document.body.getBoundingClientRect().top;
            document.body.style.position = 'fixed';
            document.body.style.top = `${this.bodyScrollPos}px`;
        },
        UnlockBackgroundScroll() {
            document.body.style.removeProperty('position');
            document.body.style.removeProperty('top');
            if (this.$mediaBreakpointUp('sm')) {
                return;
            }
            document.documentElement.scrollTop = document.body.scrollTop =
                this.bodyScrollPos * -1;
        },
    },
});
