import Vue from 'vue';
import * as $ from 'jquery';
import Messages from '@plugins/FlashMessagePlugin';
Vue.use(Messages);
Vue.config.productionTip = false;
function SetViewPortHeight() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}
function CheckWebpFeature(callback) {
    let img = new Image();
    img.onload = function () {
        let result = img.width > 0 && img.height > 0;
        callback(result);
    };
    img.onerror = function () {
        callback(false);
    };
    img.src =
        'data:image/webp;base64,UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==';
}
SetViewPortHeight();
CheckWebpFeature((support) => {
    $('body').addClass(support ? 'webp' : 'no-webp');
});
window.addEventListener('resize', () => {
    SetViewPortHeight();
});
