import Vue from 'vue';
import MenuItems from '@components/CategoryMenuItemComponent.vue';
import Mmenu from 'mmenu-js/dist/core/oncanvas/mmenu.oncanvas';
import navbars from 'mmenu-js/dist/addons/navbars/mmenu.navbars';
import 'mmenu-js/dist/mmenu.css';
const ComponentProps = Vue.extend({
    components: { MenuItems },
    props: {
        menu: {
            type: Array,
            default: new Array(),
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
        active: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: '',
        },
        motivational: {
            type: String,
            default: null,
        },
    },
    watch: {
        menu() {
            this.BuildMenu();
        },
    },
    methods: {
        BuildMenu() {
            setTimeout(() => {
                Mmenu.addons = {
                    navbars,
                };
                new Mmenu('#category-menu', {
                    navbar: {
                        title: this.title,
                    },
                    navbars: [
                        {
                            position: 'top',
                            content: [this.motivational],
                        },
                    ],
                    extensions: [
                        'border-full',
                        'fx-panels-slide-100',
                        'multiline',
                        'theme-white',
                    ],
                });
            });
        },
    },
});
export default class CategoryMenuComponent extends ComponentProps {
}
