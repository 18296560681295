var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "category-menu" } },
    [
      _c("MenuItems", {
        attrs: {
          "menu-items": _vm.menu,
          active: _vm.active,
          label: _vm.label,
          type: _vm.type
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }