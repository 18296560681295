import _Vue from 'vue';
import VueDataScooper from 'vue-data-scooper';
import VueFlashMessage from 'vue-flash-message';
_Vue.use(VueDataScooper);
export default function FlashMessagePlugin(Vue, options) {
    Vue.use(VueFlashMessage, {
        createShortcuts: false,
        messageOptions: {
            timeout: 5000,
            pauseOnInteract: true,
        },
    });
    window.notifications = new Vue({
        el: '#flash-messages',
        mounted() {
            let self = this;
            let errorsData = self.notificationError
                ? JSON.parse(self.notificationError)
                : {};
            let message = self.notificationMessage;
            if (message) {
                self.flash(message, 'success');
            }
            if (errorsData.error && errorsData.error.length > 0) {
                for (let error of errorsData.error) {
                    self.flash(error, 'error');
                }
            }
        },
    });
    Vue.prototype.$flash = function (message, type) {
        this.flash(message, type || 'info');
    };
}
export class FlashMessagePluginOptions {
}
